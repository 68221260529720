import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {};
function loadLocaleMessages() {
    if (Object.entries(messages).length === 0) {
        const locales = require.context('./resources', true, /[A-Za-z0-9-_,\s]+\.json$/i);
        locales.keys().forEach(key => {
            const matched = key.match(/([A-Za-z0-9-_]+)\./i);
            if (matched && matched.length > 1) {
                const locale = matched[1];
                messages[locale] = locales(key);
            }
        });
    }
    return messages;
}

// Supported languages
const availableLanguages = ["en", "pt", "es"];

// Detect browser language
function getBrowserLanguage() {
    const browserLang = navigator.language || navigator.userLanguage;
    const shortLang = browserLang.split('-')[0]; // Get language code without region (e.g., "en-US" → "en")
    return availableLanguages.includes(shortLang) ? shortLang : "en"; // Fallback to "en" if not supported
}

export default new VueI18n({
    locale: localStorage.getItem('locale') || getBrowserLanguage(),
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
});
